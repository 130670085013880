'use client';

import { createContext, useContext, ReactNode, useState, useMemo } from 'react';
import {
  User,
  Project,
  Org,
  Session,
  Role,
} from '@generaltranslation/node/types/Platform';
import getGTSessionAction from '@/actions/getGTSessionAction';
import { useOrgProjectSelection } from './useOrgProjectSelection';
import { useRouter } from 'next/navigation';
interface AuthContextType {
  /** The currently authenticated user */
  user: User;
  /** The currently selected organization, null if none selected */
  currentOrg: Org | null;
  /**
   * Sets the current organization and automatically selects its first project
   * @param org - The organization to set as current
   */
  setCurrentOrg: (org: Org) => void;
  /**
   * Sets the current organization by its ID. This should be used when the org is currently not in the user's session
   * and should be called after await refreshSession().
   * @param orgId - The ID of the organization to set as current
   */
  setCurrentOrgById: (orgId: string) => void;
  /** The currently selected project, null if none selected */
  currentProject: Project | null;
  /**
   * Sets the current project and updates the URL
   * @param project - The project to set as current
   */
  setCurrentProject: (project: Project) => void;
  /**
   * Sets the current project by its ID. This should be used when the project is currently not in the user's session
   * and should be called after await refreshSession().
   * @param projectId - The ID of the project to set as current
   */
  setCurrentProjectById: (projectId: string) => void;
  /** The current session object containing auth state */
  session: Session;
  /**
   * Updates the current session
   * @param session - The new session object
   */
  setSession: (session: Session) => void;
  /**
   * Fetches and updates the current session from the server
   * @returns A promise that resolves to the new session
   */
  refreshSession: () => Promise<Session>;
  /**
   * Switches the current organization (This is different from setCurrentOrg because this also changes the route)
   * @param org - The organization to switch to
   */
  switchOrg: (org: Org) => void;
  /**
   * Switches the current project (This is different from setCurrentProject because this also changes the route)
   * @param project - The project to switch to
   */
  switchProject: (project: Project) => void;

  /** The roles of the user for the current org */
  currentRoles: Role[];
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

/**
 * Provider component that wraps your app and makes auth object available to any
 * child component that calls useAuth().
 * @param props.children - Child components to render
 * @param props.initialSession - Initial session data for authentication
 */
export function AuthProvider({
  children,
  initialSession,
}: {
  children: ReactNode;
  initialSession: Session;
}) {
  const [session, setSession] = useState(initialSession);
  const user = useMemo(() => session.user as User, [session]);

  const {
    currentOrg,
    currentProject,
    currentRoles,
    setCurrentOrg,
    setCurrentProject,
    setCurrentOrgById,
    setCurrentProjectById,
  } = useOrgProjectSelection(user);

  const refreshSession = async () => {
    const session = await getGTSessionAction();
    setSession(session);
    return session;
  };

  const switchOrg = (org: Org) => {
    setCurrentOrg(org);
    window.location.href = `/dashboard`;
  };

  const switchProject = (project: Project) => {
    setCurrentProject(project);
    window.location.href = `/dashboard/${project.id}`;
  };

  /** Combined auth context value provided to children */
  const value = {
    user,
    currentOrg,
    setCurrentOrg,
    switchOrg,
    setCurrentOrgById,
    currentProject,
    setCurrentProject,
    switchProject,
    setCurrentProjectById,
    session,
    setSession,
    refreshSession,
    currentRoles,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

/**
 * Hook that can be used to access the auth context from any child component.
 * Must be used within an AuthProvider component.
 * @throws {Error} If used outside of AuthProvider
 * @returns The auth context value
 * @example
 * const { user, currentOrg, setCurrentProject } = useAuth();
 */
export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
