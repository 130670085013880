import { useState, useEffect } from 'react';
import {
  Org,
  Project,
  Role,
  User,
} from '@generaltranslation/node/types/Platform';

/**
 * Custom hook for organization and project selection.
 * @param {User} user - The current user.
 * @returns {object} Object with current organization, project, roles and setters.
 */
export function useOrgProjectSelection(user: User) {
  const [currentOrg, setCurrentOrgState] = useState<Org | null>(null);
  const [currentProject, setCurrentProjectState] = useState<Project | null>(null);
  const [currentOrgId, setCurrentOrgById] = useState<string | null>(null);
  const [currentProjectId, setCurrentProjectById] = useState<string | null>(null);
  const [currentRoles, setCurrentRoles] = useState<Role[]>([]);

  const setCurrentOrg = (org: Org) => {
    localStorage.setItem('generaltranslation:selectedOrgId', org.id);
    setCurrentOrgState(org);
    if (org.projects && org.projects[0]) {
      setCurrentProject(org.projects[0]);
    }
    setCurrentRoles(user.roles[org.id] ?? []);
  };

  const setCurrentProject = (project: Project) => {
    localStorage.setItem('generaltranslation:selectedProjectId', project.id);
    setCurrentProjectState(project);
  };

  useEffect(() => {
    if (currentOrgId) {
      const org = user.orgs.find((org) => org.id === currentOrgId);
      if (org) {
        setCurrentOrg(org);
      }
      setCurrentOrgById(null);
    }
  }, [currentOrgId, user.orgs]);

  useEffect(() => {
    if (currentProjectId && currentOrg) {
      const project = currentOrg.projects.find(
        (project) => project.id === currentProjectId
      );
      if (project) {
        setCurrentProject(project);
      }
      setCurrentProjectById(null);
    }
  }, [currentProjectId, currentOrg]);

  // Load initial org/project from localStorage
  useEffect(() => {
    const selectedOrgId = localStorage.getItem('generaltranslation:selectedOrgId');
    const selectedProjectId = localStorage.getItem('generaltranslation:selectedProjectId');

    if (selectedOrgId && selectedOrgId !== 'undefined') {
      const org = user.orgs.find((org) => org.id === selectedOrgId);
      if (org) {
        setCurrentOrg(org);
        if (selectedProjectId && selectedProjectId !== 'undefined') {
          const project = org.projects.find((project) => project.id === selectedProjectId);
          if (project) {
            setCurrentProject(project);
          }
        }
        // Return early to avoid overwriting with user.current_org
        return;
      }
    }
    setCurrentOrg(user.current_org);
  }, [user]);

  return {
    currentOrg,
    currentProject,
    currentRoles,
    setCurrentOrg,
    setCurrentProject,
    setCurrentOrgById,
    setCurrentProjectById,
  };
}