import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/gt-next/dist/provider/ClientProviderWrapper.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/opt/render/project/src/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/(dashboard)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/packages/services/platform/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/opt/render/project/src/packages/services/platform/src/components/dashboard/provider/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/render/project/src/packages/services/platform/src/components/ui/toaster.tsx");
