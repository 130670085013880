import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { User } from '@generaltranslation/node/types/Platform.js';
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isAdmin(user: User) {
  return (
    user.email === 'brian@generaltranslation.com' ||
    user.email === 'archie@generaltranslation.com' ||
    user.email === 'ernest@generaltranslation.com'
  );
}
